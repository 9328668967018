export default [
  {
    path: '/users/create',
    name: 'users.create',
    component: () => import('@/views/UserManagement/Pages/UsersCreatePage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: 'Create user',
    },
  },
]
