import axios from 'axios'
import Affiliate from '@/Models/Affiliate'
import companies from '@/store/affiliates/companies'

const getDefaultState = () => ({
  data: {
    data: [],
    current_page: 1,
    total: 0,
    last_page: 0,
  },
  loading: false,
})

const state = getDefaultState()

const actions = {
  async fetchAffiliates({ commit }) {
    try {
      commit('setLoading')
      const response = await Affiliate.params({ page: state.data.current_page }).get()
      commit('setAffiliates', response)
      commit('setLoading', false)

      return response
    } catch (error) {
      return error
    }
  },

  async storeAffiliate(context, payload) {
    return axios.post('affiliate-sa', payload)
  },

  async updateAffiliate({ dispatch }, affiliate) {
    const response = await axios.put(`affiliate-sa/${affiliate.id}`, affiliate)

    dispatch('fetchAffiliates', state.data.current_page)

    return response
  },

  async setNote(_, { affiliateId, note }) {
    return axios.put(`affiliate-sa/note/set/${affiliateId}`, { note })
  },
}

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setAffiliates(state, data) {
    state.data = data
  },

  setCurrentPage(state, page) {
    state.data.current_page = page
  },

  setLoading(state, value = true) {
    state.loading = value
  },
}

const getters = {
  getAffiliates: state => state.data.data,
  getTotal: state => state.data.total,
  getLastPage: state => state.data.last_page,
  getCurrentPage: state => state.data.current_page,
  getLoading: state => state.loading,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules: {
    companies,
  },
}
