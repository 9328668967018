export default [
  {
    path: '/affiliates/affiliates',
    name: 'affiliate.index',
    component: () => import('@/views/Affiliates/Pages/AffiliatesIndexPage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: 'Affiliate',
      navActiveLink: 'affiliate.index',
    },
  },
  {
    path: '/affiliates/create',
    name: 'affiliate.create',
    component: () => import('@/views/Affiliates/Pages/AffiliatesCreatePage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: 'Create affiliate',
      navActiveLink: 'affiliate.create',
    },
  },
  {
    path: '/affiliates/affiliates/:hash/:affiliateId',
    name: 'affiliate.show',
    component: () => import('@/views/Affiliates/Pages/AffiliatesShowPage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: 'Show affiliate',
    },
  },
]
