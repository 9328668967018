import { mdiShoppingOutline } from '@mdi/js'

export default [
  {
    subheader: 'Marketplace',
  },
  {
    title: 'Marketplace overview',
    icon: mdiShoppingOutline,
    to: 'marketplace.index',
  },
]
