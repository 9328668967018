import axios from 'axios'
import AffiliateCompany from '@/Models/AffiliateCompany'

const getDefaultState = () => ({
  data: [],
  loading: false,
})

const state = getDefaultState()

const actions = {
  async fetchAffiliateCompanies({ commit }, hash) {
    try {
      commit('setLoading', true)
      const response = await AffiliateCompany.custom(`affiliate-sa/companies/${hash}`).get()
      commit('setAffiliateCompanies', response)
      commit('setLoading', false)

      return response
    } catch (error) {
      commit('setLoading', false)

      return error
    }
  },

  async setAffiliateComment({ dispatch }, { companyId, comment, affiliateHash }) {
    const response = await axios.put(`affiliate-sa/comment/set/${companyId}`, { comment })

    dispatch('fetchAffiliateCompanies', affiliateHash)

    return response
  },

  async markInvoiceAsPaid(_, invoiceId) {
    return axios.put(`affiliate-sa/update/invoice/${invoiceId}`)
  },
}

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setAffiliateCompanies(state, data) {
    state.data = data
  },

  setLoading(state, value = true) {
    state.loading = value
  },
}

const getters = {
  getAffiliateCompanies: state => state.data,
  getLoading: state => state.loading,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
