import axios from 'axios'

const getDefaultState = () => ({})

const actions = {
  async storeUser(context, payload) {
    return axios.post('user-management', payload)
  },
}

const state = getDefaultState()

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },
}

const getters = {}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
