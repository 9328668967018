export default [
  {
    path: '/companies',
    name: 'companies.index',
    component: () => import('@/views/Companies/Pages/CompaniesIndexPage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: 'Companies',
    },
  },
]
