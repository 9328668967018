import categories from '@/store/marketplace/categories'
import suppliers from '@/store/marketplace/suppliers'

export default {
  namespaced: true,
  modules: {
    categories,
    suppliers,
  },
}
