export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Auth/Login.vue'),
    meta: {
      layout: 'blank',
      guest: true,
      title: 'Login',
    },
  },
]
