import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from '@/store/app'
import user from '@/store/user'
import ui from '@/store/ui'
import companies from '@/store/companies'
import userManagement from '@/store/user-management'
import affiliates from '@/store/affiliates'
import marketplace from '@/store/marketplace'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    appConfig: appConfigStoreModule,
    app,
    user,
    ui,
    companies,
    userManagement,
    affiliates,
    marketplace,
  },

  actions: {
    resetState({ commit }) {
      commit('user/resetState')
      commit('ui/resetState')
      commit('companies/resetState')
      commit('userManagement/resetState')
      commit('affiliates/resetState')
      commit('marketplace/categories/resetState')
      commit('marketplace/suppliers/resetState')
    },
  },
})
