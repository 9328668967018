import axios from 'axios'

const getDefaultState = () => ({
  currentUser: {
    id: null,
    name: null,
    email: null,
    email_verified_at: null,
    created_at: null,
    updated_at: null,
    company_id: null,
    phone_number: null,
  },
})

const actions = {
  async changePassword(context, payload) {
    return axios.put('user-management/user/changePassword', payload)
  },
}

const state = getDefaultState()

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setCurrentUser(data, payload) {
    state.currentUser = payload
  },
}

const getters = {
  getCurrentUser: state => state.currentUser,
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
