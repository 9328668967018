import axios from 'axios'
import _concat from 'lodash/concat'
import _find from 'lodash/find'
import Vue from 'vue'
import _map from 'lodash/map'
import _findIndex from 'lodash/findIndex'
import CompanyPricing from '@/Models/CompanyPricing'
import { i18n } from '@/plugins/i18n'
import config from '@/config'

const licencesForActivationFormatter = (item, trial) => ({
  title: `${item.preorder_no} | ${item.company} | ${item.name} | ${item.duration} ${i18n.t('months')}`,
  subtitle: `${i18n.t('Invoiced')}: ${Vue.prototype.$moment(item.invoiced).format(config.dateTimeFormatPreview)}`,
  trial,
  preorderId: item.preorder_id,
})

const getDefaultState = () => ({
  data: {
    plans: [],
    licensesForActivation: [],
  },
})

const state = getDefaultState()

const actions = {
  async fetchPricingPlans({ commit }) {
    const response = await CompanyPricing.custom('company-management/pricing/getPricings').get()
    commit('setPricingPlans', response)

    return response
  },

  async fetchLicensesForActivation({ commit }) {
    try {
      const response = await axios.get('invoicing/licence/for-activatation')
      const withPackage = _map(response.data.with_pricing_package, item => licencesForActivationFormatter(item, false))
      const withTrial = _map(response.data.with_trial_package, item => licencesForActivationFormatter(item, true))

      commit('setLicensesForActivation', _concat(withPackage, withTrial))

      return response
    } catch (error) {
      return error
    }
  },

  async addPricingPackage({ dispatch }, payload) {
    const response = await axios.put('company-management/pricing/activatePricingPackage', payload)
    dispatch('companies/fetchCompanies', {}, { root: true })

    return response
  },

  async createPreorder({ dispatch }, payload) {
    const response = await axios.post('invoicing/preorder/create', payload)
    dispatch('companies/fetchCompanies', {}, { root: true })

    return response
  },

  async createInvoice({ dispatch, rootGetters }, { preorderId, invoiceType }) {
    const response = await axios.post('invoicing', { preorder_id: preorderId, invoice_type: invoiceType })
    dispatch('fetchLicensesForActivation')
    dispatch('companies/fetchCompanies', rootGetters['companies/getCurrentPage'], { root: true })

    return response
  },

  async activateLicence({ commit /* dispatch, rootGetters */ }, preorderId) {
    try {
      const response = await axios.put('invoicing/licence/activate', { preorder_id: preorderId })
      commit('removeLicenceForActivation', preorderId)

      // dispatch('companies/fetchCompanies', rootGetters['companies/getCurrentPage'], { root: true })

      return response
    } catch (error) {
      return error
    }
  },
}

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setPricingPlans(state, data) {
    state.data.plans = data
  },

  setLicensesForActivation(state, data) {
    state.data.licensesForActivation = data
  },

  removeLicenceForActivation(state, preorderId) {
    const foundItem = _find(state.data.licensesForActivation, ['preorderId', preorderId])

    if (foundItem) {
      const index = _findIndex(state.data.licensesForActivation, foundItem)
      state.data.licensesForActivation.splice(index, 1)
    }
  },
}

const getters = {
  getPricingPlans: state => state.data.plans,
  getLicensesForActivation: state => state.data.licensesForActivation,
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
