import axios from 'axios'
import _findIndex from 'lodash/findIndex'
import Company from '@/Models/Company'
import pricing from '@/store/companies/pricing'
import companyFilters from '@/views/Companies/Data/companyFilters'

const getDefaultState = () => ({
  companies: {
    data: [],
    current_page: 1,
    total: 0,
    last_page: 0,
  },
  filters: companyFilters,
  loading: false,
  locations: [],
})

const state = getDefaultState()

const actions = {
  async fetchCompanies({ commit }, page) {
    try {
      commit('setLoading', true)
      const response = await Company.params({ page })
        .where('name', state.filters.name)
        .where('pib', state.filters.pib)
        .where('registration_number', state.filters.registration_number)
        .where('status', state.filters.status)
        .get()
      commit('setCompanies', response)
      commit('setLoading', false)

      return response
    } catch (error) {
      commit('setLoading', false)

      return error
    }
  },

  filterCompanies({ commit, dispatch }, filters) {
    commit('setCurrentPage', 1)
    commit('setFilters', filters)
    dispatch('fetchCompanies', state.companies.current_page)
  },

  async fetchCompanyLocations({ commit }) {
    const response = await axios.get('company-management/locations/get')
    commit('setLocations', response.data)

    return response
  },

  async changeCompanyStatus({ dispatch }, { companyId, status }) {
    const response = await axios.put(`company-management/${companyId}`, { status })
    dispatch('fetchCompanies', state.companies.current_page)

    return response
  },

  async setRegistrationNumber({ dispatch }, { companyId, registrationNumber }) {
    const response = await axios.put(`company-management/set-reg-number/${companyId}`, { registration_number: registrationNumber })
    dispatch('fetchCompanies', state.companies.current_page)

    return response
  },

  async updateCompany({ dispatch }, { companyId, company }) {
    const response = await axios.put(`company-management/update-city/${companyId}`, company)
    dispatch('fetchCompanies', state.companies.current_page)

    return response
  },

  async setCompanyTrialDuration({ dispatch }, { companyId, trial_duration }) {
    const response = await axios.put(`company-management/pricing/trialDuration/${companyId}`, { trial_duration })
    dispatch('fetchCompanies', state.companies.current_page)

    return response
  },

  async createInvoice({ commit, dispatch }, { activePackageId, companyId }) {
    const response = await axios.post('invoicing', { company_licence_id: activePackageId })
    commit('setActivePackageInvoiced', { activePackageId, companyId })
    dispatch('fetchCompanies')

    return response
  },

  async updateCompanyNote({ dispatch }, payload) {
    const response = await axios.put(`company-management/note/update/${payload.companyId}`, { note: payload.note })
    dispatch('fetchCompanies')

    return response
  },

  async sendConfirmationLink({ dispatch }, companyId) {
    const response = await axios.get(`company-management/registration-link/resend/${companyId}`)
    dispatch('fetchCompanies')

    return response
  },
}

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setCompanies(state, data) {
    state.companies = data
  },

  setActivePackageInvoiced(state, { activePackageId, companyId }) {
    const companyIndex = _findIndex(state.companies.data, ['id', companyId])
    const activePackageIndex = _findIndex(state.companies.data[companyIndex].licences, ['id', activePackageId])

    state.companies.data[companyIndex].licences[activePackageIndex].invoiced = 1
  },

  setFilters(state, filters) {
    state.filters = filters
  },

  setLocations(state, locations) {
    state.locations = locations
  },

  setCurrentPage(state, page) {
    state.companies.current_page = page
  },

  setLoading(state, value) {
    state.loading = value
  },
}

const getters = {
  getCompanies: state => state.companies.data,
  getTotal: state => state.companies.total,
  getLastPage: state => state.companies.last_page,
  getCurrentPage: state => state.companies.current_page,
  getLoading: state => state.loading,
  getFilters: state => state.filters,
  getCompanyLocations: state => state.locations,
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
  modules: {
    pricing,
  },
}
