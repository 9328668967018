import srLatin from 'vee-validate/dist/locale/sr_Latin.json'

export default {
  'Welcome back!': 'Dobrodošli nazad!',
  Login: 'Prijavi se',
  Logout: 'Odjavi se',
  Close: 'Zatvori',
  Home: 'Početna',
  Add: 'Add',
  Save: 'Save',
  Cancel: 'Cancel',
  Confirm: 'Confirm',
  Companies: 'Companies',
  'Companies overview': 'Pregled kompanija',
  'Company init': 'Init',
  'Company pending': 'Pending',
  'Company active': 'Active',
  'Company rejected': 'Rejected',
  'Company blocked': 'Blocked',
  'Company deleted': 'Deleted',
  'Are you sure to change status to': 'Are you sure to change status to',
  'Change status': 'Change status',
  'Set trial duration': 'Set trial duration',
  days: 'dana',
  'Activate package': 'Activate package',
  'Are you sure you want to save?': 'Are you sure you want to save?',
  'View active package': 'View active package',
  'Active package': 'Active package',
  'Package name': 'Package name',
  Price: 'Price',
  Invoiced: 'Invoiced',
  'Contract start date': 'Contract start date',
  'Contract end date': 'Contract end date',
  'Create invoice': 'Create invoice',
  'Are you sure you want to create an invoice?': 'Are you sure you want to create an invoice?',
  Note: 'Napomena',
  'Change password': 'Change password',
  'Change password description': 'Lozinka mora da sadrži najmanje 8 karaktera, jedan specijalni karakter, jedan broj, jedno veliko i jedno malo slovo.',
  'You have successfully changed your password': 'You have successfully changed your password',
  'Create user': 'Create user',
  'User management': 'User management',
  Name: 'Name',
  Email: 'Email',
  'You have successfully created a user': 'You have successfully created a user',
  'Resend confirmation link': 'Resend confirmation link',

  columns: {
    id: 'ID',
    request_number: 'ZAHTEV BR.',
    name: 'NAZIV',
    actions: 'AKCIJE',
    pib: 'PIB',
    status: 'STATUS',
    category: 'KATEGORIJA',
    created_at: 'KREIRAN',
    date_for_offer: 'ROK ZA ODGOVOR',
    email: 'EMAIL',
    role: 'ROLA',
    trial_duration: 'TRIAL DURATION',
    pricing_package: 'PRICING PACKAGE',
  },

  fields: {
    name: 'Naziv',
    email: 'Email',
    password: 'Lozinka',
    companyName: 'Naziv kompanije',
    pib: 'PIB',
    nameAndSurname: 'Ime i prezime',
    phone: 'Telefon',
    passwordConfirm: 'Potvrda lozinke',
    privacyPolicy: 'Uslovi korišćenja',
    description: 'Opis',
    contactPerson: 'Kontakt osoba',
    category: 'Kategorija',
    categories: 'Kategorije',
    file: 'Datoteke',
    price: 'Cena',
    date_for_offer: 'Rok za odgovor',
    date_for_delivery: 'Rok za isporuku',
    request_description: 'Opis zahteva',
    quantity: 'Količina',
    unit: 'Jedinica',
    city: 'Grad',
    companyAddress: 'Adresa kompanije',
    role: 'Rola',
    trial_duration: 'Trial duration',
    pricing_id: 'Pricing plan',
    date_payed: 'Date payed',
    date_start_contract: 'Date start contract',
    oldpassword: 'Old password',
    newpassword: 'New password',
  },
  validation: {
    ...srLatin.messages,
    ...{
      required_checkbox: 'Polje {_field_} je obavezno',
      positive_number: 'The {_field_} field must be positive number',
    },
  },
}
