import { mdiLinkVariant, mdiLinkVariantPlus } from '@mdi/js'

export default [
  {
    subheader: 'Affiliates',
  },
  {
    title: 'Affiliates overview',
    icon: mdiLinkVariant,
    to: 'affiliate.index',
  },
  {
    title: 'Create affiliate',
    icon: mdiLinkVariantPlus,
    to: 'affiliate.create',
  },
]
