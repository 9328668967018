export default [
  {
    path: '/marketplace',
    component: () => import('@/views/Marketplace/Pages/MarketplaceIndexPage.vue'),
    meta: {
      layout: 'content',
    },
    children: [
      {
        path: '/',
        name: 'marketplace.index',
        component: () => import('@/views/Marketplace/Pages/CategoriesPage.vue'),
        meta: {
          layout: 'content',
          auth: true,
          title: 'Categories',
        },
      },
      {
        path: 'suppliers',
        name: 'marketplace.suppliers',
        component: () => import('@/views/Marketplace/Pages/SuppliersPage.vue'),
        meta: {
          layout: 'content',
          auth: true,
          title: 'Suppliers',
        },
      },
    ],
  },
]
