import axios from 'axios'

const getDefaultState = () => ({
  parentCategories: [],
  categories: [],
})

const state = getDefaultState()

const actions = {
  async fetchParentCategories({ commit }) {
    const response = await axios.get('market-place/categories/get-parent')

    commit('setParentCategories', response.data)

    return response
  },

  async fetchCategories({ commit }) {
    const response = await axios.get('market-place/categories/get')
    commit('setCategories', response.data)

    return response
  },

  async storeParentCategory({ dispatch }, payload) {
    const response = await axios.post('market-place/categories/create-parent', { category: payload })
    dispatch('fetchParentCategories')

    return response
  },

  async editParentCategory({ dispatch }, payload) {
    const parentId = payload.id
    const response = await axios.put(`market-place/categories/edit-parent/${parentId}`, payload)
    dispatch('fetchParentCategories')

    return response
  },

  async destroyParentCategory({ dispatch }, parentCategoryId) {
    const response = await axios.delete(`market-place/${parentCategoryId}`)
    dispatch('fetchCategories')
    dispatch('fetchParentCategories')

    return response
  },

  async putCategoriesIntoParentCategory({ dispatch }, { parent_id, category }) {
    const response = await axios.put('market-place/categories/put-into-parent', { parent_id, category })
    dispatch('fetchCategories')
    dispatch('fetchParentCategories')

    return response
  },

  async changeCategoryParent({ dispatch }, { categoryId, parentId }) {
    const response = await axios.put(`market-place/categories/edit-child/${categoryId}/${parentId}`)
    dispatch('fetchCategories')
    dispatch('fetchParentCategories')

    return response
  },

}

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setParentCategories(state, data) {
    state.parentCategories = data
  },

  setCategories(state, data) {
    state.categories = data
  },
}

const getters = {
  getParentCategories: state => state.parentCategories,
  getCategories: state => state.categories,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
