import axios from 'axios'
import Supplier from '@/Models/Supplier'

const getDefaultState = () => ({
  data: {
    data: [],
    current_page: 1,
    total: 0,
    last_page: 0,
  },
  loading: false,
})

const state = getDefaultState()

const actions = {
  async fetchSuppliers({ commit }, page) {
    try {
      commit('setLoading', true)
      const response = await Supplier
        .params({ page })
        .custom('market-place')
        .get()
      commit('setSuppliers', response)
      commit('setLoading', false)

      return response
    } catch (error) {
      return error
    }
  },

  async changeSupplierStatus({ dispatch }, { supplierId, status }) {
    const response = await axios.put(`market-place/${supplierId}`, { status })
    dispatch('fetchSuppliers', state.data.current_page)

    return response
  },
}

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setSuppliers(state, data) {
    state.data = data
  },

  setCurrentPage(state, page) {
    state.data.current_page = page
  },

  setLoading(state, value = true) {
    state.loading = value
  },
}

const getters = {
  getSuppliers: state => state.data.data,
  getTotal: state => state.data.total,
  getLastPage: state => state.data.last_page,
  getCurrentPage: state => state.data.current_page,
  getLoading: state => state.loading,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
