import { mdiTagMultipleOutline } from '@mdi/js'

export default [
  {
    subheader: 'Companies',
  },
  {
    title: 'Companies overview',
    icon: mdiTagMultipleOutline,
    to: 'companies.index',
  },
]
