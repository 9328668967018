<template>
  <div class="text-center">
    <v-menu
      v-model="open"
      offset-y
      offset-overflow
      :nudge-width="350"
      :nudge-left="175"
      right
      max-height="450"
      :close-on-content-click="false"
      @input="onInput($event)"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          overlap
          color="primary"
          offset-x="13"
          offset-y="13"
          :content="items.length"
          :value="items.length"
          bordered
        >
          <v-btn
            icon
            color="secondary"
            dark
            v-bind="attrs"
            small
            v-on="on"
          >
            <v-icon>{{ icons.mdiArchiveClockOutline }}</v-icon>
          </v-btn>
        </v-badge>
      </template>
      <v-card
        max-width="530"
        class="mx-auto"
      >
        <v-overlay
          :value="loading"
          absolute
          color="secondary"
        >
          <v-progress-circular
            indeterminate
            size="32"
          />
        </v-overlay>
        <v-list
          dense
          subheader
          two-line
        >
          <v-subheader class="text-sm justify-space-between pa-2">
            <span>{{ $t('Licences') }}</span>
            <v-btn icon>
              <v-icon @click="getData()">
                {{ icons.mdiRefresh }}
              </v-icon>
            </v-btn>
          </v-subheader>

          <v-divider />

          <!-- Empty state -->
          <template v-if="isEmpty">
            <v-card-text class="d-flex justify-center align-center">
              <p class="ma-0">
                {{ $t('No data available') }}
              </p>
            </v-card-text>
          </template>
          <!-- Empty state -->

          <template v-if="!isEmpty">
            <v-list-item
              v-for="(item, index) in items"
              :key="index"
              class="py-6 mt-2"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                  <v-chip
                    v-if="item.trial"
                    color="warning"
                    small
                  >
                    {{ $t('Trial') }}
                  </v-chip>
                </v-list-item-title>

                <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  :title="`${$t('Activate')}`"
                  color="primary"
                  small
                  outlined
                  @click="openDialogConfirmation(index)"
                >
                  <v-icon
                    left
                    small
                  >
                    {{ icons.mdiArchiveCheckOutline }}
                  </v-icon>
                  {{ $t('Activate') }}
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-card>
    </v-menu>

    <v-dialog
      v-model="dialogConfirmationVisible"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          {{ $t('Licence activation') }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          {{ $t('Are you sure you want to activate the licence?') }}
        </v-card-text>

        <v-card-text v-if="selectedLicence">
          {{ $t('Details') }}: <span class="font-weight-semibold">{{ selectedLicence.title }}</span>
        </v-card-text>

        <v-divider />

        <v-card-actions class="d-flex justify-end">
          <v-btn
            text
            small
            color="error"
            :disabled="loading"
            @click="closeDialogConfirmation"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
            small
            color="primary"
            :loading="loading"
            @click="activate"
          >
            {{ $t('Confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiArchiveClockOutline,
  mdiArchiveCheckOutline,
  mdiRefresh,
} from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AppBarLicensesActivation',

  data() {
    return {
      open: false,
      loading: false,
      dialogConfirmationVisible: false,
      selectedLicence: null,
      icons: {
        mdiArchiveClockOutline,
        mdiArchiveCheckOutline,
        mdiRefresh,
      },
    }
  },

  computed: {
    ...mapGetters('companies', ['getCurrentPage']),
    ...mapGetters('companies/pricing', ['getLicensesForActivation']),

    items() {
      return this.getLicensesForActivation
    },

    isEmpty() {
      return this.items.length === 0
    },

    isCompaniesIndexRoute() {
      return this.$router.currentRoute.name === 'companies.index'
    },
  },

  async mounted() {
    await this.getData()
  },

  methods: {
    ...mapActions('companies', ['fetchCompanies']),
    ...mapActions('companies/pricing', ['fetchLicensesForActivation', 'activateLicence']),

    async getData() {
      try {
        this.loading = true
        await this.fetchLicensesForActivation()
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },

    async activate() {
      try {
        this.loading = true
        await this.activateLicence(this.selectedLicence.preorderId)
        this.$toast.success(this.$t('You have successfully activated licence'))

        if (this.isCompaniesIndexRoute) {
          await this.fetchCompanies(this.getCurrentPage)
        }

        this.dialogConfirmationVisible = false

        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },

    openDialogConfirmation(index) {
      this.open = false
      this.selectedLicence = this.items[index]
      this.dialogConfirmationVisible = true
    },

    closeDialogConfirmation() {
      this.dialogConfirmationVisible = false

      setTimeout(() => {
        this.selectedLicence = null
      }, 500)
    },

    onInput(event) {
      if (event) {
        this.fetchLicensesForActivation()
      }
    },
  },
}
</script>
