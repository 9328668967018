import { mdiAccountPlusOutline } from '@mdi/js'

export default [
  {
    subheader: 'User management',
  },
  {
    title: 'Create user',
    icon: mdiAccountPlusOutline,
    to: 'users.create',
  },
]
