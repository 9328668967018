import en from 'vee-validate/dist/locale/en.json'

export default {
  'Welcome back!': 'Welcome back!',
  Login: 'Login',
  Logout: 'Logout',
  Close: 'Close',
  Home: 'Home',
  Add: 'Add',
  Save: 'Save',
  Create: 'Create',
  Cancel: 'Cancel',
  Confirm: 'Confirm',
  Companies: 'Companies',
  Expired: 'Expired',
  Yes: 'Yes',
  No: 'No',
  'Companies overview': 'Companies overview',
  'Company init': 'Init',
  'Company pending': 'Pending',
  'Company active': 'Active',
  'Company rejected': 'Rejected',
  'Company blocked': 'Blocked',
  'Company deleted': 'Deleted',
  'Supplier init': 'Init',
  'Supplier pending': 'Pending',
  'Supplier active': 'Active',
  'Supplier rejected': 'Rejected',
  'Supplier blocked': 'Blocked',
  'Supplier deleted': 'Deleted',
  'Are you sure to change status to': 'Are you sure to change status to',
  'Change status': 'Change status',
  'Set trial duration': 'Set trial duration',
  day: 'day',
  days: 'days',
  'Activate package': 'Activate package',
  'Are you sure you want to save?': 'Are you sure you want to save?',
  'View active package': 'View active package',
  'Active package': 'Active package',
  'Package name': 'Package name',
  Price: 'Price',
  Invoiced: 'Invoiced',
  Inactive: 'Inactive',
  'Contract start date': 'Contract start date',
  'Contract end date': 'Contract end date',
  'Create invoice': 'Create invoice',
  'Are you sure you want to create an invoice?': 'Are you sure you want to create an invoice?',
  Note: 'Note',
  Edit: 'Edit',
  'Change password': 'Change password',
  'Change password description': 'Password must contain at least 8 characters, one special character, one number, one uppercase and one lowercase letter.',
  'You have successfully changed your password': 'You have successfully changed your password',
  'Create user': 'Create user',
  'User management': 'User management',
  Name: 'Name',
  Email: 'Email',
  'You have successfully created a user': 'You have successfully created a user',
  'Resend confirmation link': 'Resend confirmation link',
  'Set registration number': 'Set registration number',
  'View company details': 'View company details',
  'Edit company details': 'Edit company details',
  'Company details': 'Company details',
  'Edit company': 'Edit company',
  Phone: 'Phone',
  PIB: 'PIB',
  'Created at': 'Created at',
  'Date payed': 'Date payed',
  'Date activated': 'Date activated',
  'Registration number': 'Registration number',
  'Create preorder': 'Create preorder',
  'Select licence': 'Select licence',
  'You have successfully created preorder': 'You have successfully created preorder',
  Duration: 'Duration',
  'Select preorder': 'Select preorder',
  'You have successfully created invoice': 'You have successfully created invoice',
  'You have successfully created invoice and licence activated': 'You have successfully created invoice and licence activated',
  'You have successfully activated licence': 'You have successfully activated licence',
  Serbia: 'Serbia',
  Ino: 'Ino',
  'Activate licence': 'Activate licence',
  'Selected package': 'Selected package',
  'Preorder type': 'Preorder type',
  'This company has active licence.': 'This company has active licence.',
  'You cannot activate it at this moment.': 'You cannot activate it at this moment.',
  City: 'City',
  Licences: 'Licences',
  Activate: 'Activate',
  Trial: 'Trial',
  'No data available': 'No data available',
  'Licence activation': 'Licence activation',
  'Are you sure you want to activate the licence?': 'Are you sure you want to activate the licence?',
  Details: 'Details',
  months: 'months',
  Affiliates: 'Affiliates',
  'Affiliates overview': 'Affiliates overview',
  'Create affiliate': 'Create affiliate',
  Person: 'Person',
  Company: 'Company',
  Back: 'Back',
  Transaction: 'Transaction',
  Package: 'Package',
  Amount: 'Amount',
  Share: 'Share',
  Paid: 'Paid',
  Transactions: 'Transactions',
  'Mark as paid': 'Mark as paid',
  'Invoice no.': 'Invoice no.',
  noTransactionsMessage: 'There is no transactions yet',
  'Phone number': 'Phone number',
  'Contract number': 'Contract number',
  'Company name': 'Company name',
  Comment: 'Comment',
  Location: 'Location',
  'View only': 'View only',
  Marketplace: 'Marketplace',
  'Marketplace overview': 'Marketplace',
  Address: 'Address',
  Status: 'Status',

  columns: {
    id: 'ID',
    request_number: 'REQUEST NO.',
    name: 'NAME',
    actions: 'ACTIONS',
    pib: 'PIB',
    status: 'STATUS',
    category: 'CATEGORY',
    created_at: 'CREATED AT',
    date_for_offer: 'RESPONSE DEADLINE',
    email: 'EMAIL',
    role: 'ROLE',
    trial_duration: 'TRIAL DURATION',
    package_duration: 'PACKAGE DURATION',
    pricing_package: 'PRICING PACKAGE',
    email_and_phone: 'EMAIL AND PHONE',
    registration_number: 'REG. NUMBER',
    timeout: 'TIMEOUT',
    phone_number: 'PHONE NUMBER',
    company_name: 'COMPANY NAME',
    date_activated: 'DATE ACTIVATED',
    contract_number: 'CONTRACT NUMBER',
  },

  fields: {
    name: 'Name',
    email: 'Email',
    password: 'Password',
    companyName: 'Company name',
    pib: 'PIB',
    nameAndSurname: 'Name and surname',
    phone: 'Phone',
    passwordConfirm: 'Password confirm',
    privacyPolicy: 'Privacy policy',
    description: 'Description',
    contactPerson: 'Contact person',
    category: 'Category',
    categories: 'Categories',
    file: 'Files',
    price: 'Price',
    date_for_offer: 'Deadline for response',
    date_for_delivery: 'Deadline for delivery',
    request_description: 'Request description',
    quantity: 'Quantity',
    unit: 'Unit',
    city: 'City',
    companyAddress: 'Company address',
    role: 'Role',
    trial_duration: 'Trial duration',
    duration: 'Duration',
    pricing_id: 'Pricing plan',
    date_payed: 'Date payed',
    date_start_contract: 'Date start contract',
    oldpassword: 'Old password',
    newpassword: 'New password',
    registration_number: 'Registration number',
    phone_number: 'Phone number',
    company_name: 'Company name',
    comment: 'Comment',
    location_id: 'Location',
  },
  validation: {
    ...en.messages,
    ...{
      required_checkbox: 'The {_field_} field is required',
      positive_number: 'The {_field_} field must be positive number',
      phone: 'The {_field_} must be formatted in the format +381652013555',
      minmax: 'The {_field_} must be between {min} and {max} digits',
    },
  },
}
