export default [
  {
    path: '/user/change-password',
    name: 'user.changePassword',
    component: () => import('@/views/User/Pages/ChangePasswordPage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: 'Change password',
    },
  },
]
