import { mdiHomeOutline } from '@mdi/js'
import companies from '@/navigation/vertical/companies'
import userManagement from '@/navigation/vertical/userManagement'
import affiliates from '@/navigation/vertical/affiliates'
import marketplace from '@/navigation/vertical/marketplace'

export default [
  {
    title: 'Home',
    icon: mdiHomeOutline,
    to: 'home',
  },

  ...companies,
  ...userManagement,
  ...affiliates,
  ...marketplace,
]
