import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import en from 'vuetify/lib/locale/en'
import sr from 'vuetify/lib/locale/sr-Latn'
import preset from '@/@core/preset/preset'

Vue.use(Vuetify)

export default new Vuetify({
  preset,
  lang: {
    locales: { sr, en },
    defaultLocale: 'en',
    current: 'en',
  },
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
  },
})
