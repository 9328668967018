<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <v-img :src="require(`@/assets/images/avatars/${currentUser.file_name ? currentUser.file_name : '1.png'}`)" />
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            v-if="currentUser"
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img :src="require(`@/assets/images/avatars/${currentUser.file_name ? currentUser.file_name : '1.png'}`)" />
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ currentUser ? currentUser.fullName : '' }}
          </span>
          <!--          <small class="text&#45;&#45;disabled text-capitalize">Admin</small>-->
        </div>
      </div>

      <v-divider />

      <v-list-item :to="{name: 'user.changePassword'}">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLockOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('Change password') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <!-- Logout -->
      <v-list-item @click="logout">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('Logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiInformationVariant,
  mdiLockOutline,
} from '@mdi/js'

import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiInformationVariant,
        mdiLockOutline,
      },
    }
  },

  computed: {
    ...mapGetters('user', ['getCurrentUser']),

    currentUser() {
      return this.getCurrentUser ? this.getCurrentUser : null
    },
  },

  methods: {
    ...mapActions(['resetState']),
    ...mapMutations('ui', ['setDialogState']),

    logout() {
      this.$auth.logout({})
        .then(() => {
          this.resetState()
        })
    },
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
